import React from "react"
import Layout from "../components/layout"
import { Container, Column } from "../components/styled"
import { graphql } from "gatsby"
import { Text, Box } from "@youngagency/young-ui"
import { I18nextContext } from "gatsby-plugin-react-i18next"
import { generateUID } from "../utils/helpers"
import { faqLinkParser } from "../utils/helpers"

const Terms = ({ data, pageContext: { i18n } }) => {
  const context = React.useContext(I18nextContext)
  const lng = context.language
  const { title: pageTitle, description: pageDescription } = data.jsonJson

  const termsJson = React.useMemo(() => {
    return data.jsonJson.terms?.[lng]
      ? data.jsonJson.terms?.[lng]
      : data.jsonJson.terms?.["en"]
  }, [lng])

  const lastTermSection = React.useMemo(() => {
    return data.jsonJson.lastSection?.[lng]
      ? data.jsonJson.lastSection[lng]
      : data.jsonJson.lastSection["en"]
  }, [lng])

  const {
    general: { footer, list, paragraph },
  } = data.jsonJson
  return (
    <Layout>
      <Container>
        <Column gap="xxs" align="start">
          <Box>
            <Text type="heading" fontSize="medium">
              {pageTitle?.[lng] ? pageTitle[lng] : pageTitle["en"]}
            </Text>
            <Text type="paragraph" fontSize="large">
              {pageDescription?.[lng]
                ? pageDescription?.[lng]
                : pageDescription?.["en"]}
            </Text>
          </Box>
          <Column gap="dot" align="start">
            <Text type="label" fontSize="large">
              {paragraph?.[lng] ? paragraph[lng] : paragraph["en"]}
            </Text>
            <ul
              style={{
                listStyle: "none",
              }}
            >
              {list.map(el => {
                return (
                  <li key={generateUID()}>
                    <Text type="paragraph" fontSize="xsmall">
                      {el?.text?.[lng] ? el?.text?.[lng] : el?.text?.["en"]}
                    </Text>
                  </li>
                )
              })}
            </ul>
            <Text type="paragraph" fontSize="xsmall">
              {footer}
            </Text>
          </Column>
          <Column gap="xxs" align="start">
            {termsJson.map(el => {
              return (
                <Column gap="dot" align="start" key={generateUID()}>
                  <Text type="label" fontSize="xlarge">
                    {el.title}
                  </Text>
                  {el.term.map(item => {
                    return (
                      <React.Fragment key={generateUID()}>
                        <Text type="paragraph" fontSize="medium">
                          {item?.link
                            ? item.paragraph
                            : faqLinkParser(item.paragraph, item.link)}
                        </Text>
                        {item?.list && (
                          <Container ml="24px">
                            <ul>
                              {item?.list.map(value => {
                                return (
                                  <li key={generateUID()}>
                                    <Text type="paragraph" fontSize="small">
                                      {value.text}
                                    </Text>
                                  </li>
                                )
                              })}
                            </ul>
                          </Container>
                        )}
                      </React.Fragment>
                    )
                  })}
                </Column>
              )
            })}
            <Text type="label" fontSize="xlarge">
              {lastTermSection.title}
            </Text>
            {lastTermSection?.term.map(el => {
              return (
                <Box>
                  <Text type="paragraph" fontSize="medium">
                    {el.text}
                  </Text>
                  <ul
                    style={{
                      marginTop: "8px",
                    }}
                  >
                    {el.terms.map(item => {
                      return (
                        <li key={generateUID()}>
                          <Text type="paragraph" fontSize="small">
                            {item.paragraph}
                          </Text>
                        </li>
                      )
                    })}
                  </ul>
                </Box>
              )
            })}
          </Column>
          <Text type="label" fontSize="small">
            04/10/2021
          </Text>
        </Column>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    jsonJson {
      title {
        it
        en
      }
      description {
        it
        en
      }
      general {
        paragraph {
          it
          en
        }
        list {
          text {
            en
            it
          }
        }
        footer
      }
      terms {
        it {
          title
          term {
            paragraph
            list {
              text
            }
          }
        }
        en {
          term {
            list {
              text
            }
            paragraph
          }
          title
        }
      }
      lastSection {
        en {
          title
          term {
            text
            terms {
              paragraph
            }
          }
        }
        it {
          title
          term {
            text
            terms {
              paragraph
            }
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default Terms
